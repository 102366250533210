import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { navigate } from 'gatsby';

import { FaFacebookF, FaGoogle } from 'react-icons/fa'; // eslint-disable-line

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { OldSocialLogin as SocialLogin } from 'react-social-login'; // eslint-disable-line

import SEO from '../../seo';

import Loading from '../../Loading/Backgrop';

import {
  signInRequest,
  signInMidiaSocial, // eslint-disable-line
} from '../../../store/modules/auth/actions';

import { View, LoginView } from './styles';

export default function Login() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  if (profile) {
    navigate('/app/editar');
  }

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = e => {
    e.preventDefault();

    if (!form.email) {
      toast.error('Por favor! preencha o email.');
      return;
    }

    if (!form.password) {
      toast.error('Por favor! preencha a sua senha.');
      return;
    }

    const { email } = form;
    const { password } = form;

    dispatch(signInRequest(email, password));
  };

  // const handleSocialLogin = async (usuario, err) => {
  //   const nameProvider = usuario._provider;
  //   const provider = usuario._profile.id;
  //   const { name, email } = usuario._profile;

  //   dispatch(signInMidiaSocial(nameProvider, provider, name, email));
  //   console.log(err); // eslint-disable-line
  // };

  return (
    <>
      <SEO title="Login" />
      <View>
        <LoginView>
          <form method="post" onSubmit={e => handleSubmit(e)}>
            <h3>Faça seu Login</h3>

            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              name="email"
              onChange={e => setForm({ ...form, email: e.target.value })}
            />

            <TextField
              id="outlined-basic"
              type="password"
              label="Senha"
              variant="outlined"
              name="password"
              onChange={e => setForm({ ...form, password: e.target.value })}
            />

            <Button type="submit" variant="contained" color="primary">
              {loading ? 'Carregando...' : 'Acessar minha conta'}
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => navigate('/password/create')}
            >
              Esqueci Senha
            </Button>

            <div className="separa">OU</div>
          </form>

          {/* <div className="redessociais">
            <SocialLogin
              provider='facebook'
              appId='1016895062083673'
              callback={handleSocialLogin}
            >
              <button
                type="button"
                className="facebook"
              >
               <FaFacebookF size={14} color="#fff" /> Login Via Facebook
              </button>
            </SocialLogin>

            <SocialLogin
              provider='google'
              appId='776444467681-qpec80e8ilc7toe7hal4mnedda33jqo6.apps.googleusercontent.com'
              callback={handleSocialLogin}
            >
              <button
                type="button"
                className="google"
              >
               <FaGoogle size={14} color="#fff" /> Login Via Google
              </button>
            </SocialLogin>
          </div> */}

          <Button
            type="button"
            variant="contained"
            color="secondary"
            onClick={() => navigate('/cadastro')}
          >
            Cadastrar uma Conta
          </Button>
        </LoginView>
      </View>

      <Loading loading={loading} />
    </>
  );
}
